import React from 'react'
import {CardMedia, Button, Grid, Container, Typography}from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 60,
        // color: "#FFF"
    },
    header: {
        // backgroundColor: "#222222",
        // color: "#FFFFF",
        // paddingTop: 32,
        // textAlign: "center",
        margin: 20,
        justifyContent: "center",
        alignItems: "center",
        // display: "flex",
        // flexDirection: "column",
        // paddingBottom: 64
    },
    h3:{
        textAlign: "center",
        color: "#FFF",
    },
    box:{
        color: "#FFF",
        margin: 20,
        textAlign: "left"
    },
    p:{
        color: "#FFF"
    }
}));

const About = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Container className={classes.h3} align="center" maxWidth="lg">
                <div className={classes.header}>
                    <Typography variant="h2" align="center">
                        What is Blackdove?
                    </Typography>
                </div>
            <Grid container spacing={3}>
                <Grid item  xs={12} sm={6}>
                    <div className={classes.h3}>
                        <Typography variant="h4" paragraph>
                            Blackdove is a platform for the exploration of artwork developed using digital tools. 
                        </Typography>
                    </div>
                    <div className={classes.box}>
                        <Typography variant="p" paragraph>Blackdove was founded with the mission to provide the very best experience in digital moving image artwork  that the world had to offer.</Typography>
                    
                        <Typography variant="p" paragraph>Digital art as a medium offers artists the creative flexibility to explore new ideas with technology and software code  that has never before been available.</Typography>
                        <Typography variant="p" paragraph>As the art itself has shifted to a digital format, so have  platform and display technologies evolved to meet the needs of clients seeking to explore and install works in their homes and offices.</Typography>
                        
                        <Typography variant="p" paragraph>We welcome you to Blackdove, a place where digital art thrives in a sustainable financial ecosystem.   Artists are compensated directly and immediately as their artwork sells via the platform.    Compensation for artists is based either marketplace or royalty based models based on popularity of work on the platform.</Typography>
                
                        <Typography variant="p" paragraph>Pricing models range from single edition works that are collectible, too limited and open editions that provide varying access or ownership models.    New works are added to the platform daily as they become available from artists.</Typography>
                        
                        <Typography variant="p" paragraph>Connectivity to your existing television can be achieved with apps for all major SmartTV platforms including Apple and Android TV platforms.    Digital canvas displays are available from 32” to 98” and provide commercial grade options for hanging art in portrait orientation with 24/7/365 capabilities. </Typography>
                    
                        <Typography variant="p" paragraph>We look forward to the opportunity to accompany you on your journey into the digital art market.    Please feel free to reach out to us with any questions or suggestions. </Typography>
                    
                        <Typography variant="p" paragraph>Marc Billings, Founder</Typography>
                    </div>
                </Grid>
                <Grid item  xs={12} sm={6}>
                    <img style={{width: "100%", height: "auto"}} src="/pictures/about-photo.jpg"/>
                </Grid>
            </Grid>
      
            </Container>
        </div>
    )
}

export default About