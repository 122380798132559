import React from 'react'
import {Link, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography, Container, Card, Grid, Button, CardHeader, CardContent, Paper} from "@material-ui/core"
import { withRouter } from "react-router-dom";
import { makeStyles, useTheme  } from "@material-ui/core/styles";
import Instagram from "../Images/Instagram.js"
import Twitter from "../Images/Twitter.js"
import Facebook from "../Images/Facebook.js"
import Vimeo from "../Images/Vimeo.js"
import GooglePlay from "../Images/google-play-badge.png"
import AppStore from "../Images/app-store-badge.svg"
const useStyles = makeStyles((theme) => ({
    root: {
        // paddingTop: 60,
        height: 350,
        padding: 20,
        marginTop: 72
    },
    button: {
    },
    button2: {
        width: 56,
        color: "#FFF",
        padding: 0,
        // fontSize: "2.5rem"
        // marginTop: 30
    },
    container: {
        marginBottom: 20
    },
    logo: {
        width: "300px",
    },
    grid:{
        height: "100%"
    },
    gridContainer: {
        textAlign: "center"
    },
    buttonSize: {
        fontSize: "3.5rem"
    }
}));


const Footer = (props) => {
    const {history} = props
    const classes = useStyles();
    const companyData = [
        {title: 'ABOUT', onClick: () => {
            history.push('/about')
        }, title: 'BRAND ASSETS', onClick: () => {
            history.push('/about')
        }, title: 'CONTACT US', onClick: () => {
            history.push('/about')
        }, title: 'TECHNOLOGY', onClick: () => {
            history.push('/about')
        },}
    ]
    const resourcesData = [
        {title: 'PARTNERS', onClick: () => {
            history.push('/about')
        }, title: 'CRESTRON', onClick: () => {
            history.push('/about')
        },}
    ]
    const supportData = [
        {title: 'CUSTOMER CARE', onClick: () => {
            history.push('/about')
        }, title: 'BILLING', onClick: () => {
            history.push('/about')
        },}
    ]
    const handleOnClick = () => {

    }
    const preventDefault = (event) => event.preventDefault();
    return ( 
        <Container disableGutters className={classes.root}>
        <Grid container className={classes.grid}>
            <Grid container alignItems="center" justify="center" spacing={5} className={classes.gridContainer}>
                <Grid container justify="center" className={classes.container} >
                    <Button className={classes.button} onClick={handleOnClick}> 
                        <img className={classes.logo} src="blackdove-logo-scaled.png"/>
                    </Button>
                </Grid>
                <Grid item xs={12} sm={4}  style={{color: "#FFF"}} >
                    <IconButton className={classes.button2} onClick={handleOnClick}> 
                        <Instagram classes={{root: classes.buttonSize}} />
                    </IconButton>
                    <IconButton className={classes.button2} onClick={handleOnClick}> 
                        <Twitter classes={{root: classes.buttonSize}}/>
                    </IconButton>
                    <IconButton className={classes.button2} onClick={handleOnClick}> 
                        <Facebook classes={{root: classes.buttonSize}}/>
                    </IconButton>
                    <IconButton className={classes.button2} onClick={handleOnClick}> 
                        <Vimeo classes={{root: classes.buttonSize}}/>
                    </IconButton>
                </Grid>     
           
                <Grid item xs={12} sm={4} style={{color: "#FFF"}}> 
                    <Typography variant="h5" color="#FFF">
                        COMPANY
                    </Typography>
                    <Button style={{color: "#888"}}onClick={() => history.push('/about')}>
                        ABOUT
                    </Button>
                    <Button style={{color: "#888"}}onClick={() => history.push('/about')}>
                    BRAND ASSETS
                    </Button>
                    <Button style={{color: "#888"}}onClick={() => history.push('/about')}>
                    CONTACT US
                    </Button>
                    <Button style={{color: "#888"}}onClick={() => history.push('/about')}>
                    TECHNOLOGY
                    </Button>
                </Grid>
           
                <Grid item  xs={12} sm={4} style={{color: "#FFF"}}>
                    <Typography variant="h5" color="#FFF">
                        RESOURCES
                    </Typography>
                    <Button style={{color: "#888"}}onClick={() => history.push('/about')}>
                    PARTNERS
                    </Button>
                    <Button style={{color: "#888"}}onClick={() => history.push('/about')}>
                        CRESTRON
                    </Button>
                </Grid>
       
                <Grid item  xs={12} sm={6} style={{color: "#FFF"}}>
                    <Typography variant="h5" color="#FFF">
                       SUPPORT
                    </Typography>
        
                    <Button style={{color: "#888"}}onClick={() => history.push('/about')}>
                        BILLING
                    </Button>
                    <Button style={{color: "#888"}}onClick={() => history.push('/about')}>
                        CUSTOMER CARE
                    </Button>
                </Grid>
     
                <Grid item  xs={12} sm={6} style={{color: "#FFF"}}>
                    <Typography variant="h5" color="#FFF">
                       DOWNLOAD THE BLACKDOVE APP
                    </Typography>
                    
                    <Button onClick={() => history.push('/about')}>
                        <img src={GooglePlay} style={{height: 90, width: 200}}/>
                    </Button>
                    <Button onClick={() => history.push('/about')}>
                        <img src={AppStore} style={{height: 60, width: 200}}/>
                    </Button>
                </Grid>
            </Grid> 
            <Grid container justify="center">
                <Grid item style={{padding: 17, color: "#FFF"}}>
                    <Typography >©2020 Blackdove. All Rights Reserved  
                    <Typography variant="a" style={{color: "#a8a6a1"}}> | <Link href="#" onClick={preventDefault} style={{color: "#a8a6a1"}}>Legal  </Link></Typography>   
                    <Typography variant="a" style={{color: "#a8a6a1"}}> | <Link href="#" onClick={preventDefault} style={{color: "#a8a6a1"}}>Privacy Policy</Link></Typography>  
                    <Typography variant="a" style={{color: "#a8a6a1"}}> | <Link href="#" onClick={preventDefault} style={{color: "#a8a6a1"}}>Terms & Conditions</Link></Typography>  
                    <Typography variant="a" style={{color: "#a8a6a1"}}> | <Link href="#" onClick={preventDefault} style={{color: "#a8a6a1"}}>Site Credits</Link></Typography>  
                    </Typography>
                </Grid>    
            </Grid> 
        </Grid>
        </Container>
    )
}
export default withRouter(Footer)