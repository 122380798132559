import React from 'react'
import {Route, Redirect} from 'react-router-dom'
// import Home from './Pages/Home'
import About from './Pages/About'
import Shop from './Pages/Shop'
import Signup from './Pages/Signup'
import Login from './Pages/Login'
import DigitalCanvas from './Pages/DigitalCanvas'

const Home = React.lazy(() => import('./Pages/Home'))

const Routes = ({}) => {
    return (
        <>
        <Redirect exact from="/" to="/home" render={(props) => <Home {...props}/>}/>
        <Route exact path="/home" render={() => <Home props={{}}/>}/> 
        <Route exact path="/about" render={() => <About props={{}}/>}/> 
        <Route exact path="/shop" render={() => <Shop props={{}} />} />
        <Route exact path="/login" render={props => <Login {...props} />} />
        <Route exact path="/digitalcanvas" render={() => <DigitalCanvas props={{}} />} />
        <Route exact path="/signup" render={props => <Signup {...props} />} />
        </>
    )
}
export default Routes