import React from 'react'
import {Slide, SvgIcon, Icon, Card, CircularProgress, Grid, Paper, CardMedia, Button, Container, Typography }from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import homeBackground from '../Images/home-background.png'
import volcano from '../Images/Volcano-home.gif'
import flower from '../Images/flower.gif'
import landscape from '../Images/landscape.gif'
import tvstream from '../Images/tv-stream.png'
import backdc from '../Images/BackDigitalCanvas.jpg'

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 72,
        // color: "#FFF"
    },
    container: {
        // height: 700,
        height: "auto"
        // [theme.breakpoints.down('xs')]:{
        //     height: 1400
        // },
    },
    image: {
        height: "100%",
        width: '100%',   
    },
    media: {
        height: "100%",
        width: "100%"
    },
    card: {
        backgroundColor: "transparent",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        padding: 30,
        position: "static",
        width: "100%",
        height: "100%", 
        background: "#222222",
        alignSelf:  "center"
    },
    gridContainer: {
        height: "100%"
    },
    firstHeader: {
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "48px",
        lineHeight: "48px",
        display: "flex",
        alignItems: "center",
        color: "#FFFFFF",
        marginBottom: 24,
    },
    firstSubheader:{
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "24px",
        display: "flex",
        alignItems: "center",
        marginBottom: 24,
        color: "#FFFFFF"
    },
}));

    
const DigitalCanvas = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Container maxWidth="false" disableGutters className={classes.container}>
                {/* <div className={classes.imageContainer}> */}
                <img src={backdc} className={classes.image}/>
                {/* </div> */}
            </Container>
            <Container maxWidth="lg" disableGutters className={classes.container}>
                <Grid container className={classes.gridContainer} >
                    <Grid item xs={12} sm={6} className={classes.card}>
                            <Typography className={classes.firstHeader}>
                                ULTRA HD Resolution
                            </Typography>
                            <Typography className={classes.firstSubheader}>
                            With the resolution that is 4 times higher than FHD, it makes the color and details of the contents more vivid and realistic. In addition, the Wide Viewing Angle applied with LG IPS Panel, provides clear contents with no distortion.
                            </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.card}>
                        <img src={volcano} className={classes.image}/>
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth="lg" disableGutters className={classes.container}>
                <Grid container className={classes.gridContainer} >
                    <Grid item xs={12} sm={8} className={classes.card}>
                        <img src={flower} className={classes.image}/>
                    </Grid>
                    <Grid item xs={12} sm={4} className={classes.card}>
                            <Typography className={classes.firstHeader}>
                                ULTRA HD Resolution
                            </Typography>
                            <Typography className={classes.firstSubheader}>
                            With the resolution that is 4 times higher than FHD, it makes the color and details of the contents more vivid and realistic. In addition, the Wide Viewing Angle applied with LG IPS Panel, provides clear contents with no distortion.
                            </Typography>
                    </Grid>
                   
                </Grid>
            </Container>
            <Container maxWidth="lg" disableGutters className={classes.container}>
                <Grid container className={classes.gridContainer} >
                    <Grid item xs={12} sm={4} className={classes.card}>
                            <Typography className={classes.firstHeader}>
                                ULTRA HD Resolution
                            </Typography>
                            <Typography className={classes.firstSubheader}>
                            With the resolution that is 4 times higher than FHD, it makes the color and details of the contents more vivid and realistic. In addition, the Wide Viewing Angle applied with LG IPS Panel, provides clear contents with no distortion.
                            </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} className={classes.card}>
                        <img src={landscape} className={classes.image}/>
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth="lg" disableGutters className={classes.container}>
                <Grid container className={classes.gridContainer} >
                    <Grid item xs={12} sm={8} className={classes.card}>
                        <img src={tvstream} className={classes.image}/>
                    </Grid>
                    <Grid item xs={12} sm={4} className={classes.card}>
                            <Typography className={classes.firstHeader}>
                                Stream Artwork
                            </Typography>
                            <Typography className={classes.firstSubheader}>
                                directly from the app.
                            </Typography>
                    </Grid>
                   
                </Grid>
            </Container>
        </div>
    )
}
export default DigitalCanvas