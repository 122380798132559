const initialState = { currentUser:[], users:[], };
export default (state = initialState, action )=>{
    switch (action.type) {
      case 'SET_USER':
        return {
          ...state,
          currentUser: action.user
        }
      case 'CLEAR_USER':
        return {
          ...state,
          currentUser: {}
        };
      case 'LOAD_USERS':
        return {
          ...state,
          users: action.user
        };
     
      default:
        return state;
    }
  };
