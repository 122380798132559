import React from "react";
import {
  Drawer,
  ListItem,
  List,
  ListItemText,
  ListItemIcon, 
  Button,
  AppBar,
  Toolbar,
  IconButton,
  useScrollTrigger,
  Slide,
  Grid,
  SvgIcon,
  Icon
} from "@material-ui/core";
import { makeStyles, useTheme  } from "@material-ui/core/styles";
// import Auction from '../Images/auction.svg'
import Auction from '../Images/Auction.js'
import Digital from '../Images/Digital.js'
import Apple from '../Images/Apple-TV-1.svg'
import ShoppingCart from '../Images/Shopping.js'
import indicator from '../Images/ActiveIndicator.png'
import MenuIcon from '@material-ui/icons/Menu';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import { withRouter } from "react-router-dom";
import BlackdoveLogo from "../Images/blackdove-logo.png"
import BlackdoveIcon from "../Images/Blackdove-logo-3.png"
import Hidden from '@material-ui/core/Hidden';
import Account from "../Pages/Account"
import { ClassRounded } from "@material-ui/icons";
const drawerWidth = 228;
const useStyles = makeStyles((theme) => ({
  drawer: {
    // position: "static",
    // [theme.breakpoints.up('sm')]: {
    //  display: "none"
    // },
    // paddingTop: 72,
  },
  nested: {
    // paddingLeft: theme.spacing(4),
    // maxWidth: 250,
    color: "#FFF",
    textAlign: "center",
    [theme.breakpoints.down('sm')]: {
      display: "none"
     },
  },
  sidebar: {
    paddingLeft: 0,
    maxWidth: 200,
    color: "#999999",
  },
  listIcon: {
    color: "#999999",
    minWidth: 18,
    paddingRight: 15
  },
  iconRoot: {
    height: "auto"
  },
  listText: {
    fontFamily: "Open Sans",
    "font-weight": "bold",
  },
  drawerPaper: {
    width: drawerWidth,
    height: "100%",
    backgroundColor: "#222222",
    paddingTop: 72,
    "box-shadow": "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  appBar: {
    backgroundColor: "#333333",
    // position: "relative",
    height: "72px",
    left: "0px",
    right: "0px",
    top: "0px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.45)",
    borderRadius: "0px 0px 24px 24px",
    zIndex: 1400,
    [theme.breakpoints.up('md')]: {
      // width: `calc(100% - ${drawerWidth}px)`,
      // marginLeft: drawerWidth,
    },
  },
  menuButton: {
    color: "#FFF",
  },
  toolbar: {
    height: "100%",
    // padding: 1,
    position: "absolute",
    width: "95vw",
    justifyContent: "space-between",
  },
  button: {
    fontSize: "1.5rem", 
    color: "#FFF", 
    left: 25
    // left: "calc(50% - 212px/2)",
    // top: 23,
    // position: "absolute",
    // width: "212px", 
    // [theme.breakpoints.down('sm')]: {
     
    // },
  },
  logo: {
    // position: "absolute",
    width: "212px", 
    height: "26px", 
    left: "calc(50% - 212px/2)",
    top: "23px",
    [theme.breakpoints.down('sm')]: {
      width: "auto"
    },
    // backgroundImage: `url(${BlackdoveLogo})`
  },
  drawerContainer: {
    // overflow: 'auto',
  },
  listIndicator:{
    backgroundImage:  `url(${indicator})`,
    minWidth: 6,
    height: 24,
    marginRight: 25
  }
}));
function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}
const NavBar = props => {
  const { history, window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  
  const handleOnClick = () => {
    history.push("/home")
  }

  const data = [
    {name: "Home", onClick: (event, index) => {
      history.push(`/home`)
      setMobileOpen(false)
      setSelectedIndex(index)
    }, icon: (<HomeOutlinedIcon/>)},
    {name: "Collect NFTs", onClick: (event, index) => {
      history.push(`/shop`) 
      setMobileOpen(false)
      setSelectedIndex(index)
    }, icon: (<Auction/>)},
    {name: "Digital Canvas", onClick: (event, index) => {
      history.push(`/digitalcanvas`)
      setMobileOpen(false)
      setSelectedIndex(index)
    }, icon: (<Digital/>)},   
  ]      
  // for Account Icon
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isOpen = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
    console.log(event.currentTarget)
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <>
    <div>
    <HideOnScroll {...props}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              size="small"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Button className={classes.button} onClick={handleOnClick}> 
              <Hidden xsDown>
              <img className={classes.logo} src={BlackdoveLogo}/>
              </Hidden>
              <Hidden smUp>
              <img className={classes.logo} src={BlackdoveIcon}/>
              </Hidden>
            </Button>
            <div>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                size="small"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <ShoppingCart/>
              </IconButton>
              <Account handleClose={handleClose} setAnchorEl={setAnchorEl} history={history} handleMenu={handleMenu} anchorEl={anchorEl} open={isOpen}/>
            </div>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
        <div className={classes.drawer} aria-label="mailbox folders">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          BackdropProps={{ invisible: true }}
          // className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          
            <List component="div" disablePadding>
            {data.map((item, index) => {
            return(
              <ListItem selected={selectedIndex === index} button={true} style={selectedIndex === index ? { color: "#FFF" } : {}} className={classes.sidebar} onClick={event => item.onClick(event, index)}>
                  <ListItemIcon classes={{root: classes.listIndicator}} style={selectedIndex === index ? {} : { backgroundImage: 'none'}}></ListItemIcon>
                  <ListItemIcon classes={{root: classes.listIcon}} style={selectedIndex === index ? { color: "#FFF" } : {}}>{item.icon}</ListItemIcon>
                  <ListItemText classes={{primary: classes.listText}}primary={item.name} />
              </ListItem>
            )})}
            </List>
        </Drawer>
        </div>
    </div>
</>
  );
};

export default withRouter(NavBar);
