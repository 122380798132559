import React, {useEffect} from 'react';
import './App.css';
import {Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Routes from './Routes';
import {Switch} from 'react-router-dom'
import NavBar from './Components/NavBar';
import { useDispatch, useSelector } from 'react-redux';
import Footer from './Components/Footer.js'

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    margin: 0,
    padding: 0
  },
  paper: {
    height: "100%",
    width: "100%",
    margin: 0,
    padding: 0
  },
  control: {
    padding: theme.spacing(2),
  },
  main: {
    height: "100%",
    // paddingTop: 48,
    backgroundColor: "#222222",
    [theme.breakpoints.up('sm')]: {
      // paddingLeft: 240,
    },
  },
  scrollbar: {
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    }
  }
}));

function App() {
  const classes = useStyles();
  const dispatch = useDispatch()


  useEffect(() => {
    
    return () => {
      
    }
  }, [])


  return (
    <div className={classes.scrollbar}>
      <NavBar />
      <div className={classes.main}>
        <React.Suspense fallback={<span>Loading...</span>}>    
          <Switch>
            <Routes />
          </Switch>
        </React.Suspense>
      </div>
      <Footer/>
    </div>
    
  );
}

export default App;

